import { render, staticRenderFns } from "./UpdateSupplierForm.vue?vue&type=template&id=32883320&scoped=true&"
import script from "./UpdateSupplierForm.vue?vue&type=script&lang=js&"
export * from "./UpdateSupplierForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32883320",
  null
  
)

export default component.exports